<template>
<div>
    <columns>
        <column>
            <data-selector
                classes="is-medium is-rounded"
                tag-class="is-medium is-rounded"
                delete-button-class="is-medium is-rounded is-danger"
                searchable
                multiple
                :items="equipment_classification_list"
                :value="report.equiment_classifications"
                @input="updateEquipmentClassifications"
                value-key="id"
                label-key="name">
                Equipment Classifications
            </data-selector>
        </column>
        <column>
            <data-selector
                classes="is-medium is-rounded"
                tag-class="is-medium is-rounded"
                delete-button-class="is-medium is-rounded is-danger"
                searchable
                multiple
                :items="commonProperties"
                v-model="selected_properties"
                value-key="attribute"
                label-key="label">
                Properties
            </data-selector>
        </column>
    </columns>
    <columns>
        <column>
            <table class="table is-fullwidth">
                <thead>
                    <draggable v-model="columns" tag="tr">
                        <th class="draggable" v-for="column in columns" :key="column.name" scope="col">
                            <div class="is-flex is-justify-content-space-between is-fullwidth">
                                {{ column.label }}
                                <span class="pointer">
                                    <icon class="has-text-warning" @click="editColumnName(column)" icon="edit" type="far" />
                                    <icon class="has-text-danger" @click="removeColumn(column)" icon="times" />
                                </span>
                            </div>
                        </th>
                    </draggable>
                </thead>
            </table>
        </column>
    </columns>
</div>    
</template>
<script>
import EquipmentMeta from '../../../store/models/reporting/EquipmentMeta'
import Report from '../../../store/models/Report'
import { mapGetters, mapMutations } from 'vuex'
import concat from 'lodash/concat'
import flatMap from 'lodash/flatMap'
import uniqBy from 'lodash/uniqBy'
import Draggable from 'vuedraggable'
import { renameColumnName } from '@/modals'

export default {

    props: {
        editing: Boolean
    },

    components: {
        Draggable
    },

    data: () => ({
        columns: [],
        selected_properties: [],
    }),

    async created() {
        if (!this.editing) {
            this.$store.commit('report/initMeta', new EquipmentMeta())
            await this.$store.dispatch('equipmentClassification/loadList')
        }
    },

    mounted() {
        if(!this.editing) {
            this.columns = this.availableColumns
            this.updateSelectProperties()
        }
    },

    methods: {
        ...mapMutations('report', [
            'updateEquipmentClassifications'
        ]),
        updateSelectProperties() {
            this.columns = uniqBy(concat(this.columns, this.selected_properties), 'name')
            this.$store.commit('report/updateColumns', this.columns)
        },
        setSelectedProperties(columns, properties) {
            this.columns = columns
            this.$store.commit('report/updateColumns', this.columns)
            this.selected_properties = properties
        },
        async removeColumn(column) {
            if(await this.$confirm({
                title: 'Remove Column',
                message: 'Are you sure you want to remove this column?'
            })) {
                const index = this.columns.indexOf(column)
                this.columns.splice(index, 1)
            }
        },
        editColumnName(column) {
            renameColumnName(column).then(name => {
                if(name) {
                    const index = this.columns.indexOf(column)
                    let updatable = this.columns[index]
                    updatable.label = name
                    this.columns.splice(index, 1, updatable)
                }
            }) 
        }
    },

    computed: {
        ...mapGetters('report', [
            'report'
        ]),
        ...mapGetters('equipmentClassification', [
            'equipment_classification_list'
        ]),

        availableColumns() {
            return concat(
                Report.baseColumns(),
                EquipmentMeta.availableColumns(),
            )
        },
        classificationProperties() {
            return flatMap(this.report.equiment_classifications, classification => classification.meta)
        },
        commonProperties() {

            if (this.report.equiment_classifications.length === 1) {
                return this.classificationProperties.map((property) => {
                    return {
                        name: property.lookup_name,
                        label: property.name,
                        type: 'property',
                        attribute: property.lookup_name
                    }
                })
            }

            return uniqBy(
                this.classificationProperties.filter((item) => {
                    return this.classificationProperties.filter((property) => property.lookup_name === item.lookup_name).length > 1
                }),
                'lookup_name'
            ).map((property) => {
                return {
                    name: property.lookup_name,
                    type: 'property',
                    attribute: property.lookup_name,
                    label: property.name
                }
            })
        }
    },

    watch: {
        'selected_properties': 'updateSelectProperties'
    }

}
</script>